body {
  margin: 0;
  padding: 0;
  background-color: black;
}
.container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 30px;
}
.button {
  width: 100px;
  margin-top: 10px;
}
.card-grid {
  margin-top: 16px;
  margin-bottom: 16px;
  display: grid;
  grid-template-columns: repeat(6, 1fr);
  grid-gap: 20px;
  @media only screen and (max-width: 600px) {
    grid-template-columns: repeat(4, 1fr);
  }
}
.card {
  width: 100px;
}
p {
  color: white;
  margin: 10px;
  text-align: center;
}
.title {
  font-size: 24px;
}