.card {
  position: relative;
  width: 120px;
  height: 150px;

  @media only screen and (max-width: 1024px) {
    width: 90px;
    height: 120px;
  };

  @media only screen and (max-width: 600px) {
    width: 60px;
    height: 75px;
  }
}
.card img {
  width: 100%;
  display: block;
  border-radius: 6px;
}
.card .front {
  position: absolute;
  transform: rotateY(90deg);
  transition: all ease-in 0.2s;
  background-color: white;
  height: 100%;
  object-fit: cover;
}
.flipped .front {
  transform: rotateY(0deg);
  transition-delay: 0.2s;
}
.card .back {
  transition: all ease-in 0.2s;
  transition-delay: 0.2s;
  width: 120px;
  height: 150px;
  background-color: #102913;
  border-radius: 6px;
  @media only screen and (max-width: 1024px) {
    width: 90px;
    height: 120px;
  };

  @media only screen and (max-width: 600px) {
    width: 60px;
    height: 75px;
  }
}
.flipped .back {
  transform: rotateY(90deg);
  transition-delay: 0s;
}